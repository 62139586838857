/**
 * Header 10 stylesheet  
 * */
 .header-10 {
    z-index: 100;
    width: 100%;
    padding-top:0;
    padding-bottom:0;
    height:80px;
    color: white;
    .header-background {
        background: #1b1f23;
    }
    .navbar {
        position: absolute;
        z-index: 2;
        top: 0;
        margin: 0;
        &.navbar-fixed-top {
            top: 0 !important;
        }
        .brand {
            padding-top: 25px;
            .font(25px, white);
            font-family: @font-family-demo;
            font-weight: 800;
            img:first-child {
                float: left;
                margin: -10px 15px 0 0;
            }
        }
        .nav {
            > li {
                margin-left: 40px;
                &:first-child {
                    margin-left: 0;
                }
                > a {
                    padding: 29px 0 27px;
                    .font(17px);
                    font-weight: 500;
                    letter-spacing: 1px;
                    .a-color(white, @silver);
                }
                &.active > a {
                    .a-color(@silver, @silver);
                }
            }
        }
        .navbar-form {
            padding: 19px 0 19px 50px;
        }
        .btn {
            padding-left: 26px;
            padding-right: 26px;
            &.btn-success {
                .font(15px, white);
                font-weight: 500;
            }
        }
        .navbar-toggle {
            margin-top: 30px;
        }
    }
}
.header-10-sub {
    position:relative;
    z-index: 50;
    .v-center;
    height: 800px;
    background-color: #1b1f23;
    .background {
        background-image:url('@{startup-basePath}common-files/img/header/img-3.jpg');
        .opacity(20);
      }
    .hero-unit {
        padding: 0;
        margin: 0;
        background-color: transparent;
        h1 {
            margin: 0 0 30px;
            .font(45px, white);
            font-family: @font-family-demo;
            font-weight:300;
        }
        p {
            .font(25px, @silver);
            font-family: @font-family-demo;
            font-weight:300;
            .line-height(37.5px);
        }
        @media (max-width: 480px) {
            h1 {
                .font(36px, white);
                font-family: @font-family-demo;
                font-weight:300;
            }
            p {
                .font(22px, @silver);
                .line-height(35px);
                font-weight:300;
            }
        }
    }
    .hero-unit.hero-unit-bordered {
        h1 {
            .font(46px, white);
            font-weight: 500;
            font-family: @font-family-demo;
            border: 8px solid white;
            display: inline-block;
            margin: 0;
            padding: 30px 40px;
        }
        @media (max-width: 480px) {
            h1 {
                .font(22px, white);
                font-weight: 500;
            }
        }
    }
    color: white;
    text-align: center;
    @media (max-width: 480px) {
        &.v-center > div {
            padding-top: 35px;
            padding-bottom: 70px;
        }
    }
    .control-btn {
        width: 53px;
        height: 53px;
        .line-height(53px);
        margin: 0;
        padding: 0;
        border-radius: 50%;
        text-align: center;
        .font(19px);
        .a-color(#fff, #fff);
        background: #121518;
        .opacity(80);
        &:hover, &:focus, &:active, &.active {
            .opacity(100);
        }
        position: absolute;
        left: 50%;
        bottom: 90px;
        margin-left: -26.5px;
        @media (max-width: 480px) {
            bottom: 30px;
        }
    }
}
