.cookiebanner .header-1 .navbar{ top: 20px !important; }
.header-1 {
    .navbar {
        .brand{
            font-weight:400;
            padding: 23px 25px 24px 0;
        }
        .nav>li{
            line-height: 13px;
            margin-left: 0;
            >a{
                font-weight:400;
                padding: 30px 25px 29px 25px;
                font-size:15px;
            }
            >a.btn{
                padding: 5px 12px 5px 12px;
                margin-left: 20px;
            }
            > a:active, > a:hover, > a:focus{
                color: white;
                background-color:  @hover-color;
            }
        }
        .nav>li.localisation{
            padding-top: 6px;
            padding-right: 10px;
            a{
                padding: 0;
            }
            a:hover{
                background:transparent;
                border-bottom: 1px solid @hover-color;
                color: @hover-color;
            }
            a.active{
                color: @hover-color;
            }
        }
        .nav.high-padding{
            padding-top:19px;
        }
        .navbar-form{
            padding-top: 20px;
             .btn{
                padding: 3px 10px;
            }
        }
         .navbar-toggle{
            background: url("/icons/menu-icon-dark@2x.png") 50% no-repeat;
        }
    }
    .navbar.white-color, .navbar.white-color-permanent{
        .nav>li{
            > a{
                color: @color-grey;
            }
            > a:active, > a:hover, > a:focus{
                color: white;
            }
        }
        .nav>li.dropdown.open{
            > a{
                color: white;
            }
        }
    }
}
.dropdown.open{
    > a, > a:hover, > a:focus{
        background: @hover-color;
        color: white !important;
    }
    .dropdown-menu{
        margin-top:0 !important;
    }
}

.dropdown-menu{
    background-color: #555d5e;
    color: white;
    border-radius: 0;
    font-size: 15px;
    margin-top:0 !important;
    > li:first-child > a{
        border-radius: 0;
        padding-top: 12px;
    }
    > li:last-child > a{
        border-radius: 0;
        padding-bottom: 12px;
    }
    > li > a{
        color: white;
        padding: 12px 80px 12px 16px;
        border-radius: 0;
    }
    li > a:hover, li > a:active, li > a:focus{
         background-color: @hover-color-submenu;
         color: white;
    }
}

body>.navbar-collapse {
    .dropdown{
        display: none;
    }
    .subnav li>a{
        color: white;
    }
    .nav li>a{
        color: white;
    }

}

.navbar-fixed-top > .navbar-collapse{
    .dropdown{
        display: block;
    }
}
.header-1-startup-antiflicker.white{
    background-color: white;
    color: @color-grey;
}