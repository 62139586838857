/**
 * Header 1 stylesheet  
 * */
 .header-1 {
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    padding-top:0;
    padding-bottom:0;
    > .container {
        position: relative;
    }
    .navbar {
        position: absolute;
        z-index: 2;
        top: 45px;
        font-family: @font-family-demo;
        font-weight: 500;
        &.navbar-fixed-top {
            position: fixed;
        }
        .brand {
            .font(25px, white);
            font-family: @font-family-demo;
            font-weight: 800;
            .a-color(white, white);
            img:first-child {
                float: left;
                margin: -10px 15px 0 0;
            }
        }
        .nav {
            > li {
                margin-left: 20px;
                &:first-child {
                    margin-left: 0;
                }
                > a {
                    padding: 35px 0 27px;
                    .font(13px, white);
                    font-weight: 800;
                    letter-spacing: 1px;
                    .a-color(white, rgba(0, 0, 0, 0.4));
                }
                &.active > a {
                    .a-color(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
                }
            }
        }
    }

}
.header-1-sub {
    position:relative;
    z-index: 50;
    left: 0;
    width: 100%;
    height: 500px;
    padding: 0;
    overflow: hidden;
    color: white;
    background-color: #222527;
    @media (max-width: 480px) {
        height: 420px;
    }
    .container {
        position: relative;
        @media (max-width: 767px) {
            margin-right: 5px;
            margin-left: 5px;
        }
    }
    h3 {
        margin: 0 0 20px 0;
        .font(36px, white);
        font-weight:500;
        @media (max-width: 600px) {
            .font(30px, white);
        }
        @media (max-width: 480px) {
            .font(26px, white);
            margin: 0 0 24px 0;
        }
    }
    .lead {
        .font(30px, white);
        font-weight: 300;
        font-family: @font-family-demo;
        margin-bottom: 0;
        @media (max-width: 600px) {
            .font(26px, white);
        }
        @media (max-width: 480px) {
            .font(22px, white);
        }
    }
    .pt-perspective {
        position: relative;
        height: 100%!important;
        -webkit-perspective: 1200px;
        -moz-perspective: 1200px;
        perspective: 1200px;
    }
    .pt-page {
        width: 100%;
        height: 100%!important;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        overflow: hidden;
        background-color: #363940;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }
    .pt-page-1 {
        .background {
            background-image: url("@{startup-basePath}common-files/img/NY_002.jpg");
            .opacity(75);
            background-position:50% 30%;
        }
    }
    .pt-page-2 {
        .background {
            background-image: url("@{startup-basePath}common-files/img/header/img-2.jpg");
            .opacity(75);
            background-position:50% 30%;
        }
    }
    .pt-page-3 {
        .background {
            background-image: url("@{startup-basePath}common-files/img/header/img-3.jpg");
            .opacity(75);
            background-position:50% 30%;
        }
    }
    .pt-page-current,
    .no-js .pt-page {
        visibility: visible;
        z-index: 1;
    }
    .pt-page-ontop {
        z-index: 999;
    }
    .page-transitions {
        * {
            line-height: normal;
        }
        .background {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            .background-size(cover);
        }
        .controls > * {
            position: absolute;
            left: 15;
            top: 410px;
            z-index: 1000;
            width: 20px;
            overflow: hidden;
            text-decoration: none;
            .font(18px);
            font-weight: normal;
            font-style: normal;
            .a-color(white, rgba(0, 0, 0, 0.4));
            .transition(color 0.25s);
            @media (max-width: 480px) {
                top: 363px;
            }
            
        }
    }
}
