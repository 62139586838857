.social{
	background-color:@background-dark-grey;
	.soc-item{
		width:33%;
		float:right;
		a{
			display:block;
			height:180px;
			background-color:@background-dark-grey;
			background-position:50% 50%;
			background-repeat:no-repeat;
			font-size:22px;
			position:relative;
			overflow:hidden;
			text-decoration:none;
			span{
			       color:#fff;
			       visibility:hidden;
			       position:absolute
			}
			span.name{top:25px;left:25px;}
			span.count{bottom:28px;font-size:28px;left:0;right:0;text-align:center;}
		}
		a:hover, a:focus{text-decoration:none;border-bottom:none;}
		a:hover span, a:focus span{visibility:visible;}
	}
	.soc-item.facebook{
		a{	background-size: 40px 40px;
			background-image:url("/img/front_v2/social/logo_facebook@2x.png");background-position-y:64px;border-bottom:none;border-top:1px solid rgba(255,255,255,0.2);}
		a:hover,  a:focus{background-color:#016bb6;}
	} 
	.soc-item.twitter{
		a{	background-size: 40px 40px;
			background-image:url("/img/front_v2/social/logo_twitter@2x.png");background-position-y:74px;border-bottom:none;border-top:1px solid rgba(255,255,255,0.4);}
		a:hover, a:focus{background-color:#08c1e2;}
	} 
	.soc-item.google{
		a{	background-size: 40px 40px;
			background-image:url("/img/front_v2/social/logo_google@2x.png");background-position-y:65px;border-bottom:none;border-top:1px solid rgba(255,255,255,0.2);}
		a:hover, a:focus{background-color:#d94b34;}
	} 
	.soc-item-holder{
		overflow:hidden;
		.soc-item{width:50%;}
	}
}
