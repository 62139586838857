/**
 * Content 8 stylesheet  
 * */
 .content-8 {
    padding-top: 130px;
    padding-bottom: 130px;
    position:relative;
    text-align: center;
    h3 {
        text-align: center;
        .font(30px, @base);
        font-family:@font-family-demo;
        margin:70px 0 35px;
    }
    p {
        .font(18px);
        margin-bottom:43px;
    }
    .img {
        max-width: 397px;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }
    .btn.btn-clear {
        .box-sizing(border-box);
        background: none;
        border: 2px solid @brand-danger;
        .font(14px);
        font-weight: 500;
        font-family: @font-family-demo;
        padding: 12px 28px 12px;
        color: @brand-danger;
        &:hover, &:focus {
            background: @brand-danger;
            color: #fff;
        }
        &:active, &.active {
            border-color: @btn-danger-active-bg;
            background: @btn-danger-active-bg;
            color: rgba(255, 255, 255, 0.75);
        }
    }
    .v-center;
}
