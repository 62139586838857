.content-36 {
    background: #1b1f23;
    text-align: center;
    color: #95a5a6;
    padding-top: 230px;
    padding-bottom: 180px;
    h2 {
        color: #fff;
        font-size: 46px;
        line-height: 55px;
        margin: 0 0 60px;
        letter-spacing: -2px;
    }
    .btn-success {
        background: #27ae60;
        font-size: 22px;
        font-weight: 500;
        padding: 15px 46px;
        margin: 0 0 55px;
        &:hover {
            background: #58D68D;
        }
    }
    p {
        margin: 0 auto;
        width: 48%;
        font-size: 20px;
        line-height: 31px;
        font-weight: 500;
        @media (max-width: 767px) {
            width: 80%;
        }
    }
}
