body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, a, u, li, div, span{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: @color-grey;
    line-height: 24px;
}
p, li{
    line-height: 24px;
}

a.stratelio{
	color: @color-red;
}

h2{
	margin-bottom: 30px;
}

h3{
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    margin-bottom: 30px;
}
h4{
    color: @color-grey;
    line-height: 40px;
}

p.team-name{
	font-weight: 500;
	color: @color-grey;
}

.m-b-0{
    margin-bottom: 0 !important;
}

.m-t-0{
    margin-top: 0 !important;
}

.p-b-0{
    padding-bottom: 0 !important;
}

.p-t-0{
    padding-top: 0 !important;
}

.p-t-50{
    padding-top: 50px !important;
}

.left{
    float: left;
}
.right{
    float: right;
}
.text-left{
    text-align: left;
}

.color-red{
    color: @color-red;
}

.btn-line-white{
    border: 1px solid white;
    background: none;
    color: white;
}
.btn-line-white:hover{
    border: 1px solid @color-red;
    background: none;
    color: @color-red;
}

.btn-line-white-and-blue{
    border: 1px solid white;
    background: none;
    color: white !important;
    span{
    	color: white !important;
    }
}
.btn-line-white-and-blue:hover{
    border: 1px solid white;
    background: white;
    color: @color-dark-blue !important;
}


.btn-default{
    border: 1px solid @color-red;
    background: @color-red;
    color: white;
}
.btn-default:hover, .btn-default:active{
    border: 1px solid @color-red;
    background: @color-red-hover;
    color: white;
}
.btn-line-white{
    background: transparent;
    border: 1px solid white;
    color: white;
}

.btn-line-white:hover, .btn-line-white:active{
    background: white;
    border: 1px solid white;
    color: @color-dark-blue;
}

.btn-line-blue{
    background: transparent;
    border: 1px solid @color-dark-blue;
    color: @color-dark-blue;
}

.btn-line-blue:hover, .btn-line-blue:active{
    background: @color-dark-blue;
    border: 1px solid @color-dark-blue;
    color: white !important;
}

.btn-line-orange{
    background: transparent;
    border: 1px solid @color-boardstrat;
    color: @color-boardstrat;
}

.btn-line-orange:hover, .btn-line-orange:active{
    background: @color-boardstrat;
    border: 1px solid @color-boardstrat;
    color: white;
}

.btn-line-green{
    background: transparent;
    border: 1px solid @color-green;
    color: @color-green;
}
.btn-line-green:hover, .btn-line-green:active{
    background: @color-green;
    border: 1px solid @color-green;
    color: white;
}
.btn-line-red{
    background: transparent;
    border: 1px solid @color-red;
    color: @color-red;
}
.btn-line-red:hover{
    background: @color-red;
    border: 1px solid @color-red;
    color: white;
}
.btn-line-red-color-red{
    border: 1px solid @color-red;
    color: @color-red;
}
.btn-line-red-color-red:hover{
    background: @color-red;
    border: 1px solid @color-red;
    color: white;
}

.btn-red{
    background: @color-red;
    border: 1px solid @color-red;
    color: white !important;
}

.control-btn.fui-arrow-down{
    width: 53px;
    height: 53px;
    line-height: 53px;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    text-align: center;
    font-size: 19px;
    font-weight: 400;
    color: #7f8c8d;
    color: #fff;
    background: #121518;
    opacity: 80;
    filter: alpha(opacity=8000);
    opacity: .8;
    filter: alpha(opacity=80);
    position: absolute;
    left: 50%;
    bottom: 90px;
    margin-left: -26.5px;
}

[class*="content-"]{
    .btn{
        padding: 15px 20px;
    }
    .features p{
        font-weight: 300;
    }
    .pt-page.dynastrat{
        background-color: @color-dark-blue;
    }
    .pt-page.chronostrat{
        background-color: @color-dark-blue;
    }
    .pt-page.teamstrat{
        background-color: @color-dark-blue;
    }
    .pt-page.boardstrat{
        background-color: @color-boardstrat;
    }
}
[class*="content-"].bg-dynastrat{
    background: @color-dark-blue;
    h1, h2, h3, h4, h5, h6, p, .features h6{
        color: white;
    }
}

[class*="content-"].bg-chronostrat{
    background: @color-green;
    h1, h2, h3, h4, h5, h6, p, .features h6{
        color: white;
    }
}

[class*="content-"].bg-teamstrat{
    background: @color-light-blue;
    h1, h2, h3, h4, h5, h6, p, .features h6{
        color: white;
    }
}
[class*="content-"].bg-boardstrat{
    background: @color-boardstrat;
    h1, h2, h3, h4, h5, h6, p, .features h6{
        color: white;
    }
}

.background-left{
    background: @color-dark-blue;
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 100%; 
    &.qui-sommes-nous{
        background-image: url('/img/front_v2/01_a_propos/01_qui_sommes_nous/quisommesnous_03.jpg');
        background-position:0, 0;
        background-size:cover;
    }
    &.devenir-expert{
        background-image: url('/img/front_v2/03_experts/01_devenir_expert/devenir-expert_11.jpg');
        background-position:100%, 100%;
        background-size:cover;
    }
    @media (max-width: 768px) {
        width: 100%;
        position: relative;
    }
}

.pt-page.dynastrat{
    background-color: @color-dark-blue;
}
.pt-page.chronostrat{
    background-color: @color-green;
}
.pt-page.teamstrat{
    background-color: @color-light-blue;
}
.pt-page.boardstrat{
    background-color: @color-boardstrat;
}
.pt-page.a-propos{
    background-color: white;
}

.content-4, .footer-6 {
    padding-top: 80px;
    padding-bottom: 80px;
}
.header-1-sub .page-transitions .caption{
    position: relative;
    left: none;
    right: none;
    bottom: none;
}

.header-10-sub{
    background-color: white;
    .hero-unit{
        p{
            text-align: left;
            .font(30px, white);
            font-weight: 300;
            line-height: normal;
            @media (max-width: 600px) {
                .font(25px, white);
            }
            @media (max-width: 480px) {
                .font(20px, white);
            }
        }
        h3{
            margin: 0;
            .font(60px, white);
            font-weight: 300;
            sup{
                font-size:30px;
                vertical-align:top;
            }
            @media (max-width: 600px) {
                .font(50px, white);
                sup{
                   .font(30px, white);
                }
            }
            @media (max-width: 480px) {
                .font(40px, white);
                margin-bottom: 10px;
                sup{
                    .font(20px, white);
                }
            }
        }
        .lead{
            text-align: left;
            .font(30px, white);
            font-weight: 300;
            line-height: 37px;
            margin-top: 40px;
            @media (max-width: 600px) {
                .font(25px, white);
            }
            @media (max-width: 480px) {
                .font(20px, white);
            }
        }
        .subtitle{
                font-size:18px;
                max-width: 600px;
        }
    }
    &.dynastrat{
        background-color: #445ba5;
        .background{
            .opacity(70);
            background-image: url("/img/front_v2/02_solutions/01_dynastrat/dynastrat_01.jpg");
        }
    }
    &.teamstrat{
        background-color: #3c9cd7;
        .background{
            .opacity(70);
            background-image: url("/img/front_v2/02_solutions/02_teamstrat/teamstrat_01.jpg");
        }
    }
    &.chronostrat{
        background-color: #00b26b;
        .background{
            .opacity(70);
            background-image: url("/img/front_v2/02_solutions/03_chronostrat/chronostrat_01.jpg");
        }
    }
    &.boardstrat{
        background-color: @color-boardstrat;
        .background{
            .opacity(70);
            background-image: url("/img/front_v2/02_solutions/05_boardstrat/boardstrat_01.jpg");
        }
    }
    &.white-background{
        background-color: white;
        h3{
            color: @color-red;
            font-weight: 300;
            margin-bottom: 10px;
        }
        h4{
            .font( 30px, @color-dark-blue);
            font-weight: 300;
            text-align: left;
            margin-bottom: 20px;
        }
        .page-transitions .caption{
            // bottom: 180px
        }
        .description{
            .font(15px, @color-grey);
            line-height: 1.7221;
        }
        .lead{
            color: @color-grey;
        }
        .subtitle{
            .font(15px, @color-grey);
            font-weight: 300;
            line-height: 1.7221;
        }
        .background{
            background-position: 0,0;
        }
    }
     &.qui-sommes-nous{
        .background{
            .opacity(100);
            background-image: url("/img/front_v2/01_a_propos/01_qui_sommes_nous/quisommesnous_01@2x.jpg");
        }
        .control-btn.fui-arrow-down{
            bottom: 30px;
        }
    }
    &.devenir-expert{
        .background{
            .opacity(100);
            background-image: url("/img/front_v2/03_experts/01_devenir_expert/devenir-expert_01@2x.jpg");
        }
    }
}
.header-1-sub {
     .pt-page{
         display: table;
         background-color: white;
     }
     &.table-like{
         .pt-page{
             display: table;
         }
         .v-center{
            display: table-cell;
            vertical-align: middle;
            margin-top: 0;
            margin-bottom: 0;
            float: none;
            height : 100%;
        }
     }
     &.full-screen{
        .background{
            background-position: 50% 0px;
        }
        .v-center{
            display: table-cell;
            vertical-align: middle;
            margin-top: 0;
            margin-bottom: 0;
            float: none;
            height : 100%;
        }
     }
     h3{
        margin: 0;
        .font(60px, white);
        font-weight: 300;
        sup{
            font-size:30px;
            vertical-align:top;
        }
        @media (max-width: 600px) {
            .font(50px, white);
            sup{
               .font(30px, white);
            }
        }
        @media (max-width: 480px) {
            .font(40px, white);
            margin-bottom: 10px;
            sup{
                .font(20px, white);
            }
        }
    }
    .lead{
        text-align: left;
        .font(30px, white);
        font-weight: 300;
        @media (max-width: 600px) {
            .font(25px, white);
        }
        @media (max-width: 480px) {
            .font(20px, white);
        }
    }
    .subtitle{
            font-size:18px;
            max-width: 600px;
    }
    .page-transitions .caption{
            // top: 260px
    }
    .pt-page-1.slider_1 .background{
        .opacity(90);
        background-image: url("/img/front_v2/00_accueil/slider_1/01_dynastrat@2x.jpg");
    }
    .pt-page-2.slider_1 .background{
        .opacity(90);
        background-image: url("/img/front_v2/00_accueil/slider_1/02_teamstrat@2x.jpg");
    }
    .pt-page-3.slider_1 .background{
        .opacity(90);
        background-image: url("/img/front_v2/00_accueil/slider_1/03_chronostrat@2x.jpg");
    }
    .pt-page-1.slider_2 .background{
        .opacity(90);
        background-image: url("/img/front_v2/00_accueil/slider_2/01_dynastrat@2x.jpg");
    }
    .pt-page-2.slider_2 .background{
       .opacity(90);
        background-image: url("/img/front_v2/00_accueil/slider_2/02_teamstrat@2x.jpg");
    }
    .pt-page-3.slider_2 .background{
        .opacity(90);
        background-image: url("/img/front_v2/00_accueil/slider_2/03_chronostrat@2x.jpg");
    }
    &.dynastrat{
        .background{
            .opacity(90);
            background-image: url("/img/front_v2/02_solutions/01_dynastrat/dynastrat_01.jpg");
        }
    }
    &.teamstrat{
        .background{
            .opacity(90);
            background-image: url("/img/front_v2/02_solutions/02_teamstrat/teamstrat_01.jpg");
        }
    }
    &.chronostrat{
        .background{
            .opacity(90);
            background-image: url("/img/front_v2/02_solutions/03_chronostrat/chronostrat_01.jpg");
        }
    }
    &.white-background{
        background-color: white;
        h3{
            color: @color-red;
            font-weight: 300;
            margin-bottom: 10px;
        }
        h4{
            .font( 30px, @color-dark-blue);
            font-weight: 300;
            margin-bottom: 20px;
        }
        .page-transitions .caption{
            // bottom: 180px
        }
        .description{
            .font(15px, @color-grey);
            line-height: 1.7221;
        }
        .lead{
            color: @color-grey;
        }
        .subtitle{
            .font(15px, @color-grey);
            font-weight: 300;
            line-height: 1.7221;
        }
        .background{
            background-position: 0,0;
        }
    }
     &.qui-sommes-nous{
        .background{
            .opacity(100);
            background-image: url("/img/front_v2/01_a_propos/01_qui_sommes_nous/quisommesnous_01@2x.jpg");
        }
    }
    &.devenir-expert{
        .background{
            .opacity(100);
            background-image: url("/img/front_v2/03_experts/01_devenir_expert/devenir-expert_01@2x.jpg");
        }
    }
    &.qui-sommes-nous{
        height: 800px !important;
        @media (max-width: 800px) {
            height: 1200px !important;
        }
        @media (max-width: 570px) {
            height: 1650px !important;
        }
        @media (max-width: 320px) {
            height: 1800px !important;
        }

    }

    &.nos-expert{
        height: 550px;
    }
    &.temoignages{
         height: 330px;
    }
    &.nos-publications{
        height: 10px;
    }
    &.notre-histoire{
        height: 450px;
    }
    &.r-et-d{
        height: 600px;    
    }

    .caption{
        text-align:left;
        font-weight: 300;
    }
    .page-transitions{
        .container.controls{
            width: 100%;
        }
        .caption{
            //  Doit se situer a la moitie de l'ecran
            // bottom: 40%;
            /*@media (max-width: 600px) {
               bottom: 240px;
            }*/
            /*@media (max-width: 480px) {
                bottom: 280px;
            }*/
        }
        .controls>*{
            top: 450px;
            @media (max-width: 600px) {
               top: 340px;
            }
            @media (max-width: 480px) {
                top: 312px;
            }
            &.control-next{
                    right: 20px;
            }
            &.control-prev{
                    left: 20px;
            }
        }
    }
}
h3.stratelio{
    color: @color-red;
}

h3.dynastrat, [class*="content-"] h3.dynastrat{
    color: @color-dark-blue;
}

h3.teamstrat{
    color: @color-light-blue;
}

h3.chronostrat{
    color: @color-green;
}
h3.boardstrat{
    color: @color-boardstrat;
}
.content-8{
    padding-bottom: 20px;
    h3{
        font-weight: 300;
        line-height: 63px;
    }
    &.white-background{
        h3{
            text-align: left;
            font-size: 60px;
            color: @color-red;
            font-weight: 300;
            margin-bottom: 10px;
        }
        h4{
            text-align: left;
            .font( 30px, @color-dark-blue);
            font-weight: 300;
            margin-bottom: 20px;
        }
        .page-transitions .caption{
            // bottom: 180px
        }
        .description{
            .font(15px, @color-grey);
            line-height: 1.7221;
        }
        .lead{
            text-align: left;
            font-size: 30px;
            font-weight: 300;
            color: @color-grey;
        }
        .subtitle{
            .font(15px, @color-grey);
            font-weight: 300;
            line-height: 1.7221;
        }
    }
}
.content-11{
    color: white;
    margin-bottom: 40px;
    padding-top: 45px;
    padding-bottom: 45px;
    font-weight: 300;
    .btn {
        padding: 15px 20px;
        color: @color-red;
    }
    .btn:hover {
        color: white;
    }
}
.content-12 .btn-wide{
    background: none;
}
.content-4{
    padding: 150px 0;
    h4{
        text-align:center;
        font-size: 30px;
    }
    [class*="col-sm-"] h6{
        color: @color-grey;
        font-size: 18px;
    }
    p{
        font-weight: 300;
    }
    ul > li{
        font-size: 15px;
        color: white;
    }
    .features {
        text-align: center;
        >[class*="col-sm-"]{
            padding-top: 0;
            >img:first-child{
                position: relative;
                top:0;
                left:0;
            }
        }
        img{
            margin: 40px auto;
            // width: 100px;
        }
        p{
            font-size: 15px;
        }
        h6{
            color: @color-grey;
            text-align: center;
            font-size: 18px;
        }
    }
}

.content-5{
    padding: 150px 0;
    h3{
        color: @color-grey;;
        font-size: 30px;
        font-weight: 300;
    }
    .features >[class*="col-sm-"]{
        img{
            border-radius: 10%;
        }
        h6{
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 5px; 
            color:#ed3932;
            &.smaller{
                font-size: 16px;
                margin-bottom: 5px;
                margin-top: 20px;
                color: @color-grey;
            }
        }
        p.subtitle{
            font-weight: 500;
            font-size: 16px;
        }
        p.citation{
            margin-bottom: 0;
        }
        p{
            font-size: 15px;
            font-weight: 300;
        }
    }
    &.nos-experts{
        .features{
            margin-bottom: 40px;
        }
    }
}

.content-19 .col-sm-7 .quote:before{
    background: url(/img/front_v2/icons/quote-up-mb@2x.png) no-repeat;
}

.content-5{
    padding: 150px 0;
    &.temoignages{
        h3{
            text-align: left;
            margin: 30px 0 10px 0;
        }
        .features{
            margin-bottom: 0;
            font-size: 16px;
            line-height:1.43;
            >[class*="col-sm-"] p{
                font-size: 16px;
            }
            >[class*="col-sm-"]{
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
        p.job{
            margin-bottom: 2px;
        }
    }
    &.gamme-stratelio{
        padding-top: 60px;
        h3{
            color: @color-dark-grey;
        }
        .features >[class*="col-sm-"]{
            h6{
                font-size: 25px;
                font-weight: 300;
                color: @color-dark-grey;
            }
            p {
                color: @color-dark-grey;
            }
        }
    }
}

.content-25{
    padding: 150px 0;
    h3{
        font-weight: 300;
    }
    p, u, li{
        font-size: 15px;
        font-weight: 300;
        color: @color-grey;
    }
    p,  u{
        margin-top: 20px;
    }
}

.padding-top-0{
    padding-top: 0;
}
.padding-bottom-0{
    padding-bottom: 0;
}


.padding-bottom-100{
    padding-bottom: 100px;
}


.content-27{
    padding: 150px 0;
    h3, h4, p{
        color: @color-grey;
    }
    h3{
        font-size: 30px;
    }
    h4{
        font-size: 15px;
        font-weight: 500;
    }
    p{
        font-size: 15px;
        font-weight: 300;
    }
    #background {
        width: 100%; 
        height: 100%; 
        position: fixed; 
        left: 0px; 
        top: 0px; 
        z-index: -1; /* Ensure div tag stays behind content; -999 might work, too. */
    }
    
    .stretch {
        width:100%;
        height:100%;
    }
    // &.qui-sommes-nous{
    //     .background-left{
    //         background-image: url("");c
    //         background-repeat:no-repeat;
    //         background-size: 100%;
    //     }
    // }
}
.content-100{
    .pt-page {
        position: relative;
        top: 0;
        left: 0;
        background-color: #363940;
        padding: 75px 0;
    }
    h3{
        color: white;
        font-weight:500;
        font-size:36px;
        line-height: 45px;
    }
    h4{
        color: white;
        font-size: 36px;
        font-weight:300;
        padding: 0 0 20px 0;
    }
    p{
        color: white;
        font-weight:300;
        font-size: 15px;
    }
    .dynastrat .background{
        .opacity(100);
        background-image: url("/img/front_v2/02_solutions/01_dynastrat/dynastrat_07.jpg");
    }
    .chronostrat .background{
        .opacity(100);
        background-image: url("/img/front_v2/02_solutions/03_chronostrat/chronostrat_07.jpg");
    }
    .teamstrat .background{
        .opacity(100);
        background-image: url("/img/front_v2/02_solutions/02_teamstrat/teamstrat_03.jpg");
    }
    .notre-histoire .background{
        .opacity(100);
        background-image: url("/img/front_v2/01_a_propos/02_notre_histoire/notrehistoire_02.jpg");
    }
}

.footer-2{
    background: #555d5e;
}

.contacts-2 {
    padding-top: 0;
    .links, .links a{
        color: @color-red;
        font-weight: 600;
    }
    form{
        border: none;
        padding: 15px 0 0 0;
        .form-control{
            border: 1px solid #bdc3c7;    
        }
        input, textarea{
            margin-bottom: 5px;
        }
        input[type=text], textarea {
          -webkit-transition: all 0.30s ease-in-out;
          -moz-transition: all 0.30s ease-in-out;
          -ms-transition: all 0.30s ease-in-out;
          -o-transition: all 0.30s ease-in-out;
          outline: none;
          padding: 3px 0px 3px 3px;
          margin: 5px 1px 3px 0px;
          border: 1px solid #DDDDDD;
        }
        input[type=text]:focus, textarea:focus {
        //   box-shadow: 0 0 5px @color-red;
          padding: 3px 0px 3px 3px;
          margin: 5px 1px 3px 0px;
          border: 1px solid @color-grey;
        }

    }
}
.form-control{
    height: 36px;
    background: transparent;
    margin-top: 10px;
    border: 1px solid #bdc3c7;
    font-size: 16px;
}

input[type=text], textarea, .form-control {
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid #DDDDDD;
}
input[type=text]:focus, textarea:focus, .form-control:focus,  {
//   box-shadow: 0 0 5px @color-red;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid @color-grey;
}
.contacts-2 .form-group.has-success input[type=text], textarea, .form-control,  {
  border: 1px solid #2ecc71;
}
.contacts-2 .form-group.has-error input[type=text], textarea, .form-control,  {
  border: 1px solid #e74c3c;
}
.contacts-2 input[type=submit].btn.btn-line-red:focus, input[type=submit].btn.btn-line-red:focus{
    border: 1px solid @color-red;
    background: @color-red-hover;
}

.has-feedback .form-control-feedback{
    top: 15px;
    line-height: 15px;
}
.loader{
    background:url(/img/front_v2/loader_red.gif) no-repeat;
    height: 80px;
    width: 75px;
    margin: 0 auto;
}

.logo-clients{
    img{
        margin-right: 30px;
        margin-bottom: 20px;
    }
}
.logo-clients-full{
    img{
        margin-right: 50px;
        margin-bottom: 70px;
    }
}