/**
 * Content 4 stylesheet  
 * */
 .content-4 {
    padding-top: 130px;
    padding-bottom: 120px;
    position:relative;
    h3 {
        .font(30px, @text-color-heading);
        font-family:@font-family-demo;
        margin-bottom: 24px;
        font-weight: 300;
    }
    p {
        .font(16px);
        font-family:@font-family-demo;
        .line-height(26px);
    }
    .features {
        h6 {
            margin: 0 0 13px;
            .font(24px, @base);
            font-family:@font-family-demo;
            font-weight:500;
            text-transform: none;
        }
        p {
            .font(16px);
        }
        >[class*="col-sm-"] {
            position: relative;
            padding-top: 180px;
            padding-bottom: 50px;
            > img:first-child {
                position: absolute;
                left: 15px;
                top: 30px;
            }
        }
        &:last-child >[class*="col-sm-"], &.last >[class*="col-sm-"] {
            padding-bottom: 0;
        }
        @media (max-width: 767px) {
            >[class*="col-sm-"], &:last-child >[class*="col-sm-"], &.last >[class*="col-sm-"] {
                padding-top: 150px;
                padding-bottom: 20px;
            }
            &:last-child, &.last {
                >[class*="col-sm-"]:last-child, >[class*="col-sm-"].last {
                    padding-bottom: 0;
                }
            }
        }
    }
}
