/**
 * Footer 2 stylesheet  
 * */
 .footer-2 {
    padding-top: 20px;
    padding-bottom: 20px;
    color: @silver;
    .line-height(25px);
    a {
        .a-color(@silver);
        font-weight: normal;
    }
    nav {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            .font(16px, @silver);
            li {
                float: left;
                margin-left: 25px;
                .line-height(25px);
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    .social-btns {
        white-space: nowrap;
        > * {
            display: inline-block;
            vertical-align: top;
            margin-left: 15px;
            .font(20px, mix(@silver, black, 80%));
            width: 20px;
            height: 25px;
            overflow: hidden;
            text-align: center;
            &:first-child {
                margin-left: 0;
            }
            > * {
                display: block;
                position: relative;
                top: 0;
                .transition(0.25s top);
                &:first-child {
                    color: @silver;
                }
            }
            &:hover > * {
                top: -100%;
            }
        }
    }
    .additional-links {
        .font(12px, @silver);
        text-align: center;
        @media (max-width: 991px) {
            clear: both;
            text-align: left;
        }
        a {
            text-decoration: underline;
        }
    }
    @media (max-width: 480px) {
        padding-top: 28px;
        padding-bottom: 28px;
        > .container > * {
            float: none !important;
            .clearfix();
        }
        nav ul li {
            float: none;
            margin: 18px 0 0;
            &:first-child {
                margin-top: 0;
            }
        }
        .social-btns {
            margin: 25px 0 10px;
        }
    }
}
